import React from "react";
import {useAuth} from "react-oidc-context";
import {Box, ContentLayout, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import rocket from "../images/rocket.png";


export const NotFound = () => {
    const auth = useAuth();
    return (
        <PageLayout auth={auth}>
            <ContentLayout
                headerVariant="high-contrast"
                defaultPadding={false}
                maxContentWidth={1040}
                disableOverlap={true}

            >
                <SpaceBetween size="xl" direction="vertical" alignItems="center">
                    <img src={rocket} alt="rocket" style={{marginTop: 50}}/>
                    <SpaceBetween size="s">
                        <Box textAlign="center" fontSize="heading-s" fontWeight="bold">Houston, we have a problem.</Box>
                        <Box textAlign="center" fontSize="body-m">404 Not Found</Box>
                    </SpaceBetween>
                </SpaceBetween>
            </ContentLayout>
        </PageLayout>
    );
}