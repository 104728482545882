import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {useState} from "react";
import {useGetUserQuery} from "../services/idp/idp";

export const useSearchUserHook = (): {
    data: any,
    handleSearch: (alias: string) => Promise<
        | void
        | {
        data: void;
        error?: FetchBaseQueryError | SerializedError;
    }
    >;
    isError: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    error: any;
} => {
    const [alias, setAlias] = useState("");
    const {data, isLoading, isError, error, isSuccess} = useGetUserQuery(alias, {skip: !alias});

    const handleSearch = async (alias: string) => {
        setAlias(alias)
    }

    return {
        data,
        handleSearch,
        isError,
        isSuccess,
        isLoading,
        error,
    };
}