import React, {FormEvent, useEffect, useState} from "react";
import {Button, Form, FormField, Input, SpaceBetween} from "@amzn/awsui-components-react/polaris";

interface SetOtpPinFormProps {
    loggedInUsername: string | undefined;
    error: string;
    onSubmit: (pin: string) => void;
}

interface SetOtpPinFormState {
    pin: string,
    pinError: string,
    confirmPin: string,
    confirmPinError: string,
    hasSubmittedAtLeastOnce: boolean
}

const initialFormState: SetOtpPinFormState = {
    pin: "",
    pinError: "",
    confirmPin: "",
    confirmPinError: "",
    hasSubmittedAtLeastOnce: false
}

const pinRegex = /^[a-zA-Z0-9]{6,}$/;

export const SetOtpPinForm = ({loggedInUsername, error, onSubmit}: SetOtpPinFormProps) => {
    const [formState, setFormState] = useState(initialFormState)

    useEffect(() => {
        let pinError = ""
        if (formState.pin.length === 0) {
            pinError = "PIN is required"
        } else if (!pinRegex.test(formState.pin)) {
            pinError = "Invalid PIN"
        }

        let confirmPinError = ""
        if (formState.confirmPin.length === 0) {
            confirmPinError = "Please confirm your PIN"
        } else if (formState.confirmPin !== formState.pin) {
            confirmPinError = "PINs do not match"
        }

        if (formState.pinError !== pinError || formState.confirmPinError !== confirmPinError) {
            setFormState({...formState, pinError: pinError, confirmPinError: confirmPinError})
        }
    }, [formState]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!(formState.pinError || formState.confirmPinError)) {
            onSubmit(formState.pin);
            setFormState(initialFormState)
        } else {
            // per Cloudscape design best practices, form validation errors should only be shown after the user first
            // attempts to submit, and then after that validation should be continuous on all form changes
            setFormState({...formState, hasSubmittedAtLeastOnce: true});
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={<Button variant="primary" data-testid="set-button">Set PIN</Button>}
                errorText={error}
                data-testid="set-pin-form"
            >
                <SpaceBetween direction="vertical" size="s">
                    <p data-testid="form-info">
                        You are setting an OTP PIN for <strong>{loggedInUsername}</strong>. Create and confirm a new PIN consisting of letters and numbers and then click the "Set" button.
                    </p>
                    <FormField label="New PIN" errorText={formState.hasSubmittedAtLeastOnce ? formState.pinError : ""} data-testid="new-pin-form-field" description="Enter a new PIN consisting of at least 6 numbers and/or letters.">
                        <Input type="password" value={formState.pin} onChange={(e) => setFormState({...formState, pin: e.detail.value.trim()})} data-testid="new-pin-input"/>
                    </FormField>
                    <FormField label="Confirm PIN" errorText={formState.hasSubmittedAtLeastOnce ? formState.confirmPinError: ""} data-testid="confirm-pin-form-field" description="Enter the same PIN as above.">
                        <Input type="password" value={formState.confirmPin} onChange={(e) => setFormState({...formState, confirmPin: e.detail.value.trim()})} data-testid="confirm-pin-input"/>
                    </FormField>
                </SpaceBetween>
            </Form>
        </form>
    )
}