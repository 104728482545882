import {Button, Container, Form, FormField, Input} from "@amzn/awsui-components-react/polaris";
import React, {FormEvent, useEffect, useState} from "react";

interface UserSearchFormProps {
    onSubmit: (alias: string) => void;
    formError: string;
}

interface UserSearchFormState {
    alias: string,
    aliasError: string,
    hasSubmittedAtLeastOnce: boolean
}

const initialFormState: UserSearchFormState = {
    alias: "",
    aliasError: "",
    hasSubmittedAtLeastOnce: false
}

export const UserSearchForm = ({onSubmit, formError} : UserSearchFormProps) => {
    const [formState, setFormState] = useState(initialFormState)

    useEffect(() => {
        let aliasError = ""
        if (formState.alias.length === 0) {
            aliasError = "Alias cannot be blank"
        }

        if (formState.aliasError !== aliasError) {
            setFormState({...formState, aliasError: aliasError})
        }
    }, [formState]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formState.aliasError) {
            onSubmit(formState.alias);
            setFormState(initialFormState)
        } else {
            // per Cloudscape design best practices, form validation errors should only be shown after the user first
            // attempts to submit, and then after that validation should be continuous on all form changes
            setFormState({...formState, hasSubmittedAtLeastOnce: true});
        }
    }

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Form
                    actions={<Button variant="primary" data-testid="search-button">Search</Button>}
                    errorText={formError}
                    data-testid="search-user-form"
                >
                    <FormField label="Search for a user" data-testid="search-user-alias-form-field" description="Enter the alias of the user you want to search." errorText={formState.hasSubmittedAtLeastOnce ? formState.aliasError : ""}>
                        <Input value={formState.alias} onChange={(e) => setFormState({...formState, alias: e.detail.value.trim()})} data-testid="search-user-alias-input"/>
                    </FormField>
                </Form>
            </form>
        </Container>
    )
}