import React, {useState} from "react";
import {AppLayout} from '@amzn/awsui-components-react/polaris';
import {DefaultSideNavigation} from "./side-navigation";
import TopNavigation from "./top-navigation";
import {EnableUserManagement} from "../constants/admin";
import {AuthContextProps} from "react-oidc-context";

interface PageLayoutProps {
    children: React.ReactNode;
    sideNav?: React.ReactNode;
    auth: AuthContextProps;
    sideNavOpen?: boolean;
    hideSideNav?: boolean;
}

export const PageLayout = (props: PageLayoutProps) => {
    const [navigationOpen, setNavigationOpen] = useState(props.sideNavOpen ?? true);
    const otpEnabled = props.auth.user?.profile?.otp_enabled as boolean ?? false
    const userManagementEnabled = EnableUserManagement(props.auth)
    const isManager = props.auth.user?.profile?.is_manager as boolean ?? false

    return (
        <>
            <TopNavigation loggedInAlias={props.auth.user?.profile?.preferred_username} loggedInUsername={props.auth.user?.profile?.name}/>
            <AppLayout
                disableContentPaddings={true}
                content={props.children}
                navigationHide={props.hideSideNav}
                navigation={props.sideNav ?? <DefaultSideNavigation otpEnabled={otpEnabled} userManagementEnabled={userManagementEnabled} isManager={isManager}/>}
                navigationOpen={navigationOpen}
                onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                toolsHide={true}
                headerVariant="high-contrast"
            />
        </>

    );
}