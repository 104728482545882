import React from "react";
import {useAuth} from "react-oidc-context";
import {Box, Container, ContentLayout, Header, Link, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";

interface HomePageProps {
    otpEnabled: boolean;
}

export const Home = ({otpEnabled} : HomePageProps) => {
    const auth = useAuth();
    const welcome = auth.user?.profile?.name ? `Welcome, ${auth.user?.profile.name}!` : "Welcome!";
    return (
        <PageLayout auth={auth}>
            <ContentLayout
                headerVariant="high-contrast"
                header={<Header variant="h1">{welcome}</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <SpaceBetween size="s">
                    <Container>
                        <SpaceBetween size="xxs">
                            <Header variant="h2">Kuiper Internal Identity Provider</Header>
                            <Box variant="p">
                                The Kuiper Internal Identity Provider (IdP) is the system responsible for answering the
                                question of authentication: Is the user/automation/system that claims to be, actually
                                the user they claim to be?
                            </Box>
                            <Link
                                href="https://w.amazon.com/bin/view/KuiperEnterpriseTechnology/KAAP/KIAM/KILM#HIdentityProvider28IdP29"
                                external={true} variant="primary"
                                ariaLabel="Learn more about the Kuiper Identity Provider">
                                Learn more
                            </Link>
                        </SpaceBetween>
                    </Container>
                    <Container>
                        <SpaceBetween size="xxs">
                            <Header variant="h3">What do you need to do today?</Header>
                            <Box variant="p">
                                If you need to register a new security key, <a href="/register-mfa">click here</a>.
                            </Box>
                            {otpEnabled &&
                                <>
                                    <Box variant="p">
                                        If you need to set or change your OTP PIN, <a href="/set-otp-pin">click here</a>.
                                    </Box>
                                    <Box variant="p">
                                        If you have an OTP PIN set and need to register your key to use OTP, <a href="/register-otp-key">click here</a>.
                                    </Box>
                                </>
                            }
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </ContentLayout>
        </PageLayout>
    );
}