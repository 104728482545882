import React from "react";
import {ContentLayout, Header} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useDirectUsersQuery} from "../services/idp/idp";
import {useAuth} from "react-oidc-context";
import {BreadcrumbGroup} from "@amzn/awsui-components-react";
import {UserManagementTable} from "../components/user-management-table";

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'User Management'},
        {href: '/manage-directs', text: 'Manage directs'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

export const ManageDirects = () => {
    const auth = useAuth();
    const {data} = useDirectUsersQuery(auth.user?.profile?.sub as string || "");

    return (
        <PageLayout auth={auth}>
            <ContentLayout
                headerVariant="high-contrast"
                header={<Header variant="h1" description="Manage your direct reports">Team Management</Header>}
                defaultPadding={true}
                maxContentWidth={1080}
                disableOverlap={false}
                data-testid="admin-page"
                breadcrumbs={Breadcrumbs()}
            >
                <UserManagementTable users={data?.users}/>
            </ContentLayout>
        </PageLayout>
    );
}