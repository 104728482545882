import { useState } from "react";
import {
  useRegisterOTPKeyMutation,
  useSetOTPPinMutation,
} from "../services/idp/idp";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export const useOTPKeyRegister = (): {
  registerKey: (pin: string, otp: string) => Promise<
    | void
    | {
        data: void;
        error?: FetchBaseQueryError | SerializedError;
      }
  >;
  isKeyError: boolean;
  isKeySuccess: boolean;
  isKeyLoading: boolean;
  keyError: string;
} => {
  const [keyError, setKeyError] = useState("");
  const [registration, regState] = useRegisterOTPKeyMutation();

  const registerKey = async (pin: string, otp: string) => {
    setKeyError("");
    return registration({pin, otp})
      .unwrap()
      .catch((error: any) => {
        if (error.data && error.data.code === 50201) {
          setKeyError("Too many tokens. You have already reached the maximum of 2 tokens per user.")
        } else if (error.data && error.data.code === 50200) {
            setKeyError("User is not allowed to register keys for OTP.");
        } else if (error.data && error.data.code === 50203) {
            setKeyError("Key was not found, please use a different key.");
        } else if (error.data && error.data.code === 50204) {
            setKeyError("User PIN was entered incorrectly.");
        } else if (error.data && (error.data.code === 50205 || error.data.code === 50206 || error.data.code === 50207)) {
            setKeyError("OTP is invalid.");
        } else if (error.data && error.data.code === 50208) {
            setKeyError("Key is already registered to another alias.");
        } else if (error.data && error.data.code === 50210) {
            setKeyError("Key is already registered");
        } else if (error.data) {
          setKeyError(`We were unable to register your key, please try again later. Code: ${error.data.code}`);
        } else {
          console.log(error)
          setKeyError(error.error);
        }
        return error;
      });
  }

  return {
    registerKey,
    keyError,
    isKeyError: regState.isError,
    isKeySuccess: regState.isSuccess,
    isKeyLoading: regState.isLoading
  };
};

export const useSetOTPPin = (): {
  setPin: (pin: string) => Promise<
      | void
      | {
    data: void;
    error?: FetchBaseQueryError | SerializedError;
  }
  >;
  isPinError: boolean;
  isPinSuccess: boolean;
  isPinLoading: boolean;
  pinError: string;
} => {
  const [pinError, setPinError] = useState("");
  const [registration, regState] = useSetOTPPinMutation();

  const setPin = async (pin: string) => {
    setPinError("");
    return registration({pin})
        .unwrap()
        .catch((error: any) => {
            console.log(error)
          if (error.data && error.data.code === 53001) {
            setPinError("Too many tokens. You have already reached the maximum of 2 tokens for your user.")
          } else if (error.data && error.data.code === 50209) {
              setPinError("Pin provided is too weak.")
          } else if (error.data && error.data.code === 50200) {
              setPinError("You are not authorized to use OTP.");
          } else if (error.data) {
            setPinError("Something went wrong and we could not register your OTP key, please try again later.");
          } else {
            console.log(error)
            setPinError(error.error);
          }
          return error;
        });
  }

  return {
    setPin,
    pinError,
    isPinError: regState.isError,
    isPinSuccess: regState.isSuccess,
    isPinLoading: regState.isLoading
  };
};