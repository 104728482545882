import {
    Button,
    CopyToClipboard,
    SpaceBetween,
    Modal,
    StatusIndicator
} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useGenerateRegistrationCodeMutation} from "../services/idp/idp";
import {User} from "../lib/types";

interface UserManagementSectionProps {
    user: User
}

export const GenerateRegCode = ({user}: UserManagementSectionProps) => {
    const [generateRegCode, {data, isLoading, isError, isSuccess}] = useGenerateRegistrationCodeMutation();
    const [regCode, setRegCode] = useState(""); // we need a separate state variable for the regcode so we can clear it after closing the modal

    useEffect(() => {
        if (data) {
            setRegCode(data.regcode);
        }
    }, [data]);

    const generateRegistrationCodeForUser = (item: User) => {
        generateRegCode({username: item?.Alias});
    };

    const closeRegCodeModal = () => {
        setRegCode("");
    }

    return (
        <>
            <SpaceBetween size="xs" direction="horizontal">
                <Button
                    variant="inline-link"
                    onClick={() => generateRegistrationCodeForUser(user)}
                    data-testid={`generate-regcode-button-${user?.Alias}`}
                    loading={isLoading}
                    disabled={!(user.Status === "enabled" || user.Status === "new")}
                >
                    Generate registration code
                </Button>
                {((isSuccess && !data?.regcode) || isError) && <StatusIndicator type={(isSuccess && !data?.regcode) ? "success" : "error"}></StatusIndicator>}
            </SpaceBetween>
            <Modal
                onDismiss={closeRegCodeModal}
                visible={!!regCode}
                header={`Registration code for ${user.DisplayName}`}
                data-testid="generated-regcode-modal"
            >
                <p>
                    We were unable to send an automated notification. Copy the code below and send it to {user?.DisplayName || user?.Alias} via your corporate email, Slack, or Chime.
                </p>
                <SpaceBetween direction="horizontal" alignItems="center" size="l">
                    <b data-testid="generated-regcode">{regCode}</b>
                    <CopyToClipboard
                        copyButtonText="Copy"
                        copyErrorText="Code failed to copy"
                        copySuccessText="Code copied to clipboard"
                        textToCopy={regCode}
                    />
                </SpaceBetween>

            </Modal>
        </>
    )
}