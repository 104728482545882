import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import {User} from "../lib/types";
import {BadgePhotoBaseUrl} from "../constants/urls";
import {GenerateRegCode} from "./generate-regcode";
import {Badge, StatusIndicator} from "@amzn/awsui-components-react";
import {useState} from "react";

interface UserTableProps {
    users: User[];
}

export const UserManagementTable = ({users}: UserTableProps) => {
    const [userSearchFilter, setUserSearchFilter] = useState("");

    const filteredUsers = () => {
        if (users?.length && userSearchFilter.length) {
            return users.filter(
                (item: User) =>
                    item.DisplayName
                        .toLocaleLowerCase()
                        .indexOf(userSearchFilter.toLocaleLowerCase()) >= 0 ||
                    item.Alias.toLocaleLowerCase().indexOf(userSearchFilter.toLocaleLowerCase()) >=
                    0
            );
        }
        return users ?? [];
    };

    // no need to show the OTP column if none of the users have OTP enabled; check all users instead of just filtered
    // users so that columns remain consistent even when filtering
    const showOTPColumn = users?.some(user => {
        return user.OTPEnabled;
    })

    return (
        <Table
            columnDefinitions={[
                {
                    id: "user",
                    header: "User",
                    cell: user =>
                        <SpaceBetween direction="horizontal" size="xs" alignItems={"center"}>
                            <img data-testid={`badge-photo-${user?.Alias}`} src={`${BadgePhotoBaseUrl}${user.Alias}`} alt="" style={{blockSize: "30px", borderRadius: "10%"}}/>
                            <Box variant="p">
                                {user.DisplayName}
                            </Box>
                        </SpaceBetween>,
                    isRowHeader: true,
                    minWidth: 200
                },
                {
                    id: "alias",
                    header: "Alias",
                    cell: user => user.Alias,
                },
                {
                    id: "webauthn-credentials",
                    header: "Registered keys",
                    cell: user => <Badge data-testid={`credentials-count-${user?.Alias}`}>{user.Credentials?.length || 0}/2</Badge>,
                },
                {
                    id: "status",
                    header: "Status",
                    cell: user =><StatusIndicator data-testid={`user-status-${user?.Alias}`} type={user.Status === "enabled" ? "success" : user.Status === "new" ? "pending" : user.Status === "disabled" ? "error" : "warning"}>{user.Status.charAt(0).toUpperCase() + user.Status.slice(1) || "Unknown"}</StatusIndicator>,
                },
                {
                    id: "otp",
                    header: "OTP",
                    cell: user => <StatusIndicator data-testid={`otp-status-${user?.Alias}`} type={user.OTPEnabled ? user.YubiOTPPin !== "" ? "success" : "warning" : "stopped"}>{user.OTPEnabled ? `Enabled, PIN ${user.YubiOTPPin === "" ? "not " : ""}set` : "Disabled"}</StatusIndicator>,
                },
                {
                    id: "actions",
                    header: "Actions",
                    cell: user => (
                        <GenerateRegCode user={user}></GenerateRegCode>
                    ),
                    minWidth: 250
                }
            ]}
            columnDisplay={[
                { id: "user", visible: true },
                { id: "alias", visible: true },
                { id: "webauthn-credentials", visible: true },
                { id: "status", visible: true },
                { id: "otp", visible: showOTPColumn },
                { id: "actions", visible: true }
            ]}
            items={filteredUsers()}
            loadingText="Loading directs"
            sortingDisabled
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                        <b>No users found</b>
                    </SpaceBetween>
                </Box>
            }
            filter={
                <TextFilter
                    filteringText={userSearchFilter}
                    filteringPlaceholder="Filter users"
                    filteringAriaLabel="Filter users"
                    filteringClearAriaLabel="Clear filter"
                    onChange={({detail}) =>
                        setUserSearchFilter(detail.filteringText)
                    }
                    data-testid={`direct-reports-text-filter`}
                />
            }
            header={<Header>Direct reports</Header>}
            /*    TODO: add preferences and pagination*/
        />
    );
}