import React, {useEffect, useState} from "react";
import {ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {UserSearchForm} from "../components/user-search-form";
import {useSearchUserHook} from "../hooks/search";
import {BreadcrumbGroup} from "@amzn/awsui-components-react";
import {useAuth} from "react-oidc-context";
import {UserManagementCard} from "../components/user-management-card";

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'User Management'},
        {href: '/search-users', text: 'Search users'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

export const SearchUsers = () => {
    const auth = useAuth();
    const {
        data,
        handleSearch,
        isError,
        error,
    } = useSearchUserHook();
    const [searchError, setSearchError] = useState("");

    useEffect(() => {
        if (isError) {
            if (error.data && error.data.code === 51500) {
                setSearchError("User not found.");
            } else if (error.data && error.data.code === 51501) {
                setSearchError("You do not have permission to view this user.");
            } else {
                setSearchError("An unknown error has occurred.");
            }
        } else {
            setSearchError("")
        }
    }, [isError, error])

    return (
        <PageLayout auth={auth}>
            <ContentLayout
                headerVariant="high-contrast"
                header={<Header variant="h1" description="Search for and manage users">User Search</Header>}
                defaultPadding={true}
                maxContentWidth={1080}
                disableOverlap={false}
                data-testid="admin-page"
                breadcrumbs={Breadcrumbs()}
            >
                <SpaceBetween size="m" direction="vertical">
                    <UserSearchForm onSubmit={handleSearch} formError={searchError}/>
                    {data && <UserManagementCard user={data.user}/>}
                </SpaceBetween>
            </ContentLayout>
        </PageLayout>
    );
}