import React from 'react';
import {SideNavigation, SideNavigationProps} from '@amzn/awsui-components-react/polaris';

interface SideNavProps {
    otpEnabled: boolean;
    userManagementEnabled: boolean;
    isManager: boolean;
}

// for now, while we have few pages with simple paths, we can use window.location.pathname (with trailing slashes
// removed, since /page and /page/ are the same) to set the current active ref; as we add more pages or more complex
// navigation item hrefs, consider exposing activeHref as a prop
const activeHref = window.location.pathname.replace(/\/+$/, '');

export const DefaultSideNavigation = ({otpEnabled, userManagementEnabled, isManager}: SideNavProps) => {
    const keyManagementNavItems: SideNavigationProps.Item[] = [
        {type: 'link', text: 'Register security key', href: '/register-mfa'},
    ]
    const userManagementNavItems: SideNavigationProps.Item[] = []

    if (otpEnabled) {
        keyManagementNavItems.push(
            {type: 'link', text: 'Set OTP PIN', href: '/set-otp-pin'},
            {type: 'link', text: 'Register OTP key', href: '/register-otp-key'},
        )
    }

    if (isManager) {
        userManagementNavItems.push({type: 'link', text: 'Manage directs', href: '/manage-directs'})
    }

    if (userManagementEnabled) {
        userManagementNavItems.push({type: 'link', text: 'Search users', href: '/search-users'})
    }

    let defaultNavItems: SideNavigationProps['items'] = [
        {
            type: 'section',
            text: 'Key Management',
            items: keyManagementNavItems,
        }
    ]

    if (userManagementNavItems.length) {
        defaultNavItems = [...defaultNavItems, {
            type: 'section',
            text: 'User Management',
            items: userManagementNavItems,
        }]
    }

    return <SideNavigation items={defaultNavItems} activeHref={activeHref}/>;
}
