import * as React from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import {User} from "../lib/types";
import {BadgePhotoBaseUrl} from "../constants/urls";
import {GenerateRegCode} from "./generate-regcode";
import {Badge, ColumnLayout, Container, ExpandableSection, StatusIndicator} from "@amzn/awsui-components-react";

interface UserCardProps {
    user: User;
}

export const UserManagementCard = ({user}: UserCardProps) => {
    return (
        <Container>
            <SpaceBetween direction="vertical" size="m">
                <SpaceBetween direction="horizontal" alignItems={"center"} size="s">
                    <img src={`${BadgePhotoBaseUrl}${user.Alias}`} data-testid="badge-photo"
                         alt="" style={{blockSize: "50px", borderRadius: "10%"}}/>
                    <SpaceBetween direction="vertical" size="xxxs">
                        <Box variant="h2">
                            {user.DisplayName}
                        </Box>
                        <Box variant="h5">
                            {user.Alias}
                        </Box>
                    </SpaceBetween>
                </SpaceBetween>
                <ColumnLayout columns={user.OTPEnabled ? 4 : 3} variant="text-grid">
                    <div>
                        Registered keys <Badge data-testid={"credentials-count"}>{user.Credentials?.length || 0}/2</Badge>
                    </div>
                    <div>
                        <StatusIndicator type={user.Status === "enabled" ? "success" : user.Status === "new" ? "pending" : user.Status === "disabled" ? "error" : "warning"} data-testid="user-status">
                            Account is {user.Status}
                        </StatusIndicator>
                    </div>
                    <div>
                        <StatusIndicator type={user.OTPEnabled ? "success" : "stopped"} data-testid="otp-status">
                            OTP is {user.OTPEnabled ? "enabled" : "disabled"}
                        </StatusIndicator>
                    </div>
                    {user.OTPEnabled &&
                        <div>
                            <StatusIndicator type={user.YubiOTPPin !== "" ? "success" : "warning"} data-testid="otp-pin-status">
                                OTP PIN is {user.YubiOTPPin !== "" ? "" : "not "}set
                            </StatusIndicator>
                        </div>
                    }
                </ColumnLayout>
                <ExpandableSection headerText="Actions">
                    <GenerateRegCode user={user}></GenerateRegCode>
                </ExpandableSection>
            </SpaceBetween>
        </Container>
    );
}