import React from "react";
import {TopNavigation, TopNavigationProps} from '@amzn/awsui-components-react/polaris';
import logo from "../images/kuiper-logo-light.png";
import {PhoneToolUserBaseUrl} from "../constants/urls";

interface NavigationProps {
    loggedInUsername?: string;
    loggedInAlias?: string;
    activeHref?: string;
}

const DefaultTopNavigation = ({loggedInUsername, loggedInAlias}: NavigationProps) => {
    const navigationIdentity = {
        href: '/',
        title: "Internal Identity Provider",
        logo: {src: logo, alt: 'Kuiper logo'},
    }

    const navigationUtilities: ReadonlyArray<TopNavigationProps.Utility> = [
        {
            type: 'button',
            text: loggedInUsername,
            href: `${PhoneToolUserBaseUrl}${loggedInAlias}`,
            iconName: "user-profile",
            external: false,
            externalIconAriaLabel: ' (opens in new tab)',
        }
    ]

    return <TopNavigation identity={navigationIdentity} utilities={navigationUtilities}/>
}

export default DefaultTopNavigation;
