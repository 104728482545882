import React, {FormEvent, useEffect, useState} from "react";
import {Button, Form, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import ShowAlerts from "../components/alerts"
import {Alerts} from "../lib/types";
import {FormField, Input} from "@amzn/awsui-components-react";

interface RegisterMfaFormProps {
    loggedInUsername: string | undefined;
    error: string;
    onSubmit: (otp: string) => void;
    isChromium: boolean;
    isSuccess: boolean;
}

interface RegisterOtpKeyFormState {
    otp: string,
    otpError: string,
    hasSubmittedAtLeastOnce: boolean
}

const initialFormState: RegisterOtpKeyFormState = {
    otp: "",
    otpError: "",
    hasSubmittedAtLeastOnce: false
}

export const RegisterMfaForm = ({loggedInUsername, error, onSubmit, isSuccess, isChromium}: RegisterMfaFormProps) => {
    const [formAlerts, setFormAlerts] = useState<Alerts>({})
    const [formState, setFormState] = useState(initialFormState)
    const [authInProgress, setAuthInProgress] = useState(false)

    useEffect(() => {
        let otpError = ""
        if (formState.otp.length === 0) {
            otpError = "OTP is required"
        } else if (formState.otp.length !== 44) {
            otpError = "Invalid OTP length"
        } else if (formState.otp !== formState.otp.toLowerCase()) {
            otpError = "Invalid OTP, your caps lock may be on"
        }
        if (formState.otpError !== otpError) {
            setFormState({...formState, otpError: otpError})
        }
    }, [formState]);

    // clear the form and re-enable fields after response
    useEffect(() => {
        if (isSuccess || error) {
            setFormState(initialFormState)
            setAuthInProgress(false)
        }
    }, [isSuccess, error])

    useEffect(() => {
        if (!isChromium) {
            setFormAlerts(prevAlerts => ({
                ...prevAlerts, "browser_compatability_warning": {
                    message: "There are known issues with registering FIDO2 keys in this browser. Please use Chrome for the best registration experience.",
                    ariaLabel: "Warning",
                    type: "warning",
                }
            }));
        }
    }, [isChromium]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formState.otpError) {
            onSubmit(formState.otp);
            setAuthInProgress(true)
            // don't clear the OTP form yet since the user can technically cancel out of the registration popup
        }
        // per Cloudscape design best practices, form validation errors should only be shown after the user first
        // attempts to submit, and then after that validation should be continuous on all form changes
        setFormState({...formState, hasSubmittedAtLeastOnce: true});
    }

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={<Button disabled={authInProgress} variant="primary" data-testid="register-button">Register</Button>}
                errorText={error}
                data-testid="register-mfa-form"

            >
                <SpaceBetween direction="vertical" size="xs">
                    {!!Object.keys(formAlerts).length && <>{ShowAlerts(formAlerts)}</>}
                    <p data-testid="form-info">You are registering a new security key for <strong>{loggedInUsername}</strong>. You will need to touch your YubiKey 3 times to complete the registration process.</p>
                    <FormField label="OTP" errorText={formState.hasSubmittedAtLeastOnce ? formState.otpError: ""} description="Press and hold your key for 3-5 seconds to generate a OTP." data-testid="mfa-otp-form-field">
                        <Input disabled={authInProgress} type="password" value={formState.otp} onChange={(e) => setFormState({...formState, otp: e.detail.value.trim()})} data-testid="mfa-otp-input"/>
                    </FormField>
                </SpaceBetween>
            </Form>
        </form>
    )
}